<template>
  <div class="productItem activeProduct">

    <div class="card">
      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> Tracker bearbeiten</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeModal', 'ConfigWorkflowTracker');$store.commit('closeConfigWorkflowTracker');">
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
          class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
        </button>
        <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
          class="fa-duotone fa-trash"></i> {{ $t("globals.delete") }}
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Name</label>
              <input class="form-control" type="text" v-model="d.title">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Zustand</label>

              <select  class="form-select" v-model="d.state">
                <option :value="opt.value" v-for="opt in stateOptions">{{opt.label}}</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Sortierung</label>
              <input class="form-control" type="number" v-model="d.ordering" step="1">
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>{{$t("globals.color")}}</label>
              <input class="form-control" type="color" v-model="d.color">
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label> {{$t("globals.iconList")}}</label>
              <br>
              <a href="https://fontawesome.com/search?o=r&s=duotone" target="_blank">
                Font Awesome Duotone</a>
            </div>
          </div>
            <div class="col-3">
              <div class="form-group">
                <label>{{$t("globals.sign")}}</label>
                <input class="form-control" type="text" v-model="d.icon"  >
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Workflow/IssueTracker/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "TrackerDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs", "getConfigWorkflowTracker"])
  },
  data() {
    return {
      d: {
        title: "",
        state: "calc",
        ordering: 0,
      },
      isNew: true,
      stateOptions: [
        {value: "calc", label: this.$t("states.issueTracker.calc")},
        {value: "order", label: this.$t("states.issueTracker.order")},
        {value: "bug", label: this.$t("states.issueTracker.bug")},
      ],
    };
  },
  methods: {


    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/issueTracker/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess

          // Load Products

          this.$store.dispatch("loadConfig", {type: "issue", subType: 'trackers', percent: 95, message: 'fast fertig... Ende in Sicht'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Tracker wurde erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigWorkflowTracker');
          });

        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
      else {
        axios.post(this.$store.state.apiUrl + "config/issueTracker/", VueInner.d, {headers}).then(() => {



          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "issue", subType: 'trackers', percent: 95, message: 'fast fertig... Ende in Sicht'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigWorkflowTracker');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Tracker wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigWorkflowTracker');
          });

          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll der Tracker wirklich gelöscht werden?', 'Tracker löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "config/issueTracker/" + VueInner.d.id, {headers}).then(() => {
          // close

          // dispatch toastSuccess

          this.$store.dispatch("loadConfig",{type: "issue", subType: 'trackers', percent: 95, message: 'fast fertig... Ende in Sicht'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Tracker wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigWorkflowTracker');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
        this.d = this.getConfigWorkflowTracker

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>

