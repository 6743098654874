<template>
  <div class="card-header card-header-inline">
    <template
      v-if="getConfigs && getConfigs.params_values && (taskActive && taskActive.state_id === getConfigs.params_values.issue_default_close)">
      <button class="checkCircleBtn btn-primary btn btn-sm" @click="uncheckTask(taskActive.id)"><i
        class="fa-duotone fa-check fa-xl"></i></button>
    </template>
    <template v-else>
      <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(taskActive.id)"><i
        class="fa-duotone fa-check fa-xl"></i></button>
    </template>
    <h6 class="editLine">{{ taskActive.title }}</h6>
    <div class="btn-group float-end">
      <UploadComponent :customer-id="taskActive.crm_customer_id" :task-id="taskActive.id" :compact="true"/>
    </div>
  </div>
  <div class="card-body">
    <ul class="fileList list-group">
      <template v-if="attachments" v-for="at in attachments">
        <li class="list-group-item">
          <div class="btn-group me-3">
            <button class="btn btn-sm btn-outline-secondary" @click="downloadFile(at.customer_id, at.id)"><span
              tooltip="Download">
        <i class="fa-duotone fa-paperclip"></i></span></button>

            <button :disabled="at.contentType !=='application/pdf' && at.contentType !== 'image/jpeg' && at.contentType !== 'image/png' && at.contentType !== 'image/svg' && at.contentType !== 'image/jpg'" class="btn btn-sm btn-outline-primary" @click="previewFile(at.customer_id, at.id)"><span
              tooltip="Vorschau">
        <i class="fa-duotone fa-eye"></i></span></button>
          </div>
          <button class="btn btn-sm btn-outline-danger float-end me-2 ms-3" @click="deleteFile(at.id)">
          <span tooltip="Löschen">
          <i class="fa-trash-alt fa-duotone"></i></span></button>
          <small>{{ at.title }} - {{ $filters.formatDateTime(at.uploaded_on) }}</small>
        </li>
      </template>
    </ul>
  </div>
  <template v-if="file">
    <Modal v-if="modalsList.previewFile">
      <div class="modal show" style="display: block;">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              {{ file.title }}
              <button type="button" class="btn-close" @click="closeFile()"></button>
            </div>
            <div class="modal-body">

              <template v-if="file.contentType ==='application/pdf'  ">
                <embed style="min-height: 80vh;" :src="'data:'+file.contentType+';base64,'+file.content">
              </template>

              <template v-else>
                <img :src="'data:'+file.contentType+';base64,'+file.content"
                     style="display: block;width: 100%; height: auto;">
              </template>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </template>
</template>
<script>
import axios from "axios";
import UploadComponent from "@/directives/Upload/UploadComponent.vue";
import {mapGetters} from "vuex";
import EditorHtml from "@/components/EditorHtml.vue";

export default {
  name: "Attachments",
  emits: ["update", "checkTask", "uncheckTask"],
  props: {
    attachments: ""
  },
  components: {
    EditorHtml,
    UploadComponent
  },
  computed: {
    ...mapGetters(["modalsList", "taskActive", "getConfigs"])
  },
  data() {
    return {
      file: ''
    };
  },
  methods: {
    previewFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          // show in modal
          this.$store.commit('openModal', 'previewFile');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkTask() {
      this.$emit('checkTask');
    },
    uncheckTask(task) {
      this.$emit('uncheckTask');
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    downloadFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.title);
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFile(attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "attachment/" + attachmentId, {headers: headers})
        .then((response) => {
          // Reload Task
          this.$emit('update');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>
