// drafts.js

import router from "@/router";
import axios from "axios";

export const state = {
  activeItems: {
    customer: {},
    address: {},
    product: {},
    document: {},
    task: {},
    legal:{},
    sepa:{},
    abo:{},
    draft:{},
    event:{},
    category:{},
    employee:{},
    project: {},
    ConfigAccountingKeys:{},
    ConfigBankAccounts:{},
    ConfigCostNumbers:{},
    ConfigCustomerTaxes:{},
    ConfigCustomerAcquisitions:{},
    ConfigCustomerState:{},
    ConfigCustomerPrio:{},
    ConfigCustomerLead:{},
    ConfigCustomerType:{},
    ConfigCustomerTags:{},
    ConfigCustomerStructure:{},
    ConfigCountryConfigs:{},
    ConfigTimeConfigs:{},
    ConfigWorkflowTracker:{},
    ConfigWorkflowPrio:{},
    ConfigWorkflowStates:{},
    ConfigWorkflowActivities:{},
    ConfigLangOverride:{},
    ConfigYearplan:{},
    ConfigEmployeeCategory:{},
    filesystem:{},

  },
}

export const mutations = {
  closeCustomer(state) {
    state.activeItems.customer = null;
    router.push({name: "customers"});
  },
  closeDocument(state) {
    state.activeItems.document = null;
    router.push({name: "Documents"});
  },
  closeProduct(state) {
    state.activeItems.product = null;
    router.push({name: "products"});
  },
  closeDraft(state) {
    state.activeItems.draft = null;
    router.push({name: "drafts"});
  },
  closeCategory(state) {
    state.activeItems.category = null;
    router.push({name: "categories"});
  },
  closeConfigAccountingKeys(state) {
    state.activeItems.ConfigAccountingKeys = {};
    router.push({name: "ConfigAccountingKeys"});
  },
  closeConfigBankAccounts(state) {
    state.activeItems.ConfigBankAccounts = {};
    router.push({name: "ConfigBankAccounts"});
  },
  closeConfigCostNumbers(state) {
    state.activeItems.ConfigCostNumbers = {};
    router.push({name: "ConfigCostNumbers"});
  },
  closeConfigCustomerTaxes(state) {
    state.activeItems.ConfigCustomerTaxes = {};
    router.push({name: "ConfigCustomerTaxes"});
  },
  closeLangOverrides(state) {
    state.activeItems.ConfigLangOverride = null;
    router.push({name: "LangOverrides"});
  },
  closeConfigCustomerAcquisitions(state) {
    state.activeItems.ConfigCustomerAcquisitions = {};
    router.push({name: "ConfigCustomerAcquisitions"});
  },

  closeConfigCustomerState(state) {
    state.activeItems.ConfigCustomerState = null;
    router.push({name: "ConfigCustomerState"});
  },
  closeConfigCustomerPrio(state) {
    state.activeItems.ConfigCustomerPrio = {};
    router.push({name: "ConfigCustomerPrio"});
  },
  closeConfigCustomerLead(state) {
    state.activeItems.ConfigCustomerLead = {};
    router.push({name: "ConfigCustomerLead"});
  },
  closeConfigCustomerType(state) {
  state.activeItems.ConfigCustomerType = {};
  router.push({name: "ConfigCustomerType"});
  },
  closeConfigCustomerTags(state) {
    state.activeItems.ConfigCustomerTags = {};
    router.push({name: "ConfigCustomerTags"});
  },

  closeConfigCustomerTitle(state) {
    state.activeItems.ConfigCustomerTitle = {};
    router.push({name: "ConfigCustomerTitle"});
  },
  closeConfigYearplan(state) {
    state.activeItems.ConfigYearplan = {};
    router.push({name: "ConfigYearplanConfigs"});
  },
  closeConfigEmployeeCategory(state) {
    state.activeItems.ConfigEmployeeCategory = {};
    router.push({name: "ConfigCategoriesEmployee"});
  },
  closeEmployee(state) {
    state.activeItems.employee = null;
    router.push({name: "Employees"});
  },
  closeConfigCustomerStructure(state, subType) {
    state.activeItems.ConfigCustomerStructure[subType] = {};
    router.push({name: "ConfigCustomerStructure", params: {subType: subType}});
  },

  closeConfigCountryConfigs(state) {
    state.activeItems.ConfigCountryConfigs= {};
    router.push({name: "ConfigCountryConfigs"});
  },
  closeConfigTimeConfigs(state) {
    state.activeItems.ConfigTimeConfigs= {};
    router.push({name: "ConfigTimeConfigs"});
  },
closeConfigWorkflowTracker(state) {
  state.activeItems.ConfigWorkflowTracker= {};
  router.push({name: "ConfigWorkflowTracker"});
} ,
  closeConfigWorkflowPrio(state) {
    state.activeItems.ConfigWorkflowPrio= {};
    router.push({name: "ConfigWorkflowPrio"});
  } ,
  closeConfigWorkflowStates(state) {
    state.activeItems.ConfigWorkflowStates= {};
    router.push({name: "ConfigWorkflowStates"});
  } ,
  closeConfigWorkflowActivities(state) {
    state.activeItems.ConfigWorkflowActivities= {};
    router.push({name: "ConfigWorkflowActivities"});
  } ,
  closeLegal(state) {
    state.activeItems.legal = null;
    router.push({name: "Legals"});
  },
  closeEvent(state) {
    state.activeItems.event = null;
    router.push({name: "Events"});
  },
  closeSepa(state) {
    state.activeItems.sepa = {};
    router.push({name: "Sepa"});
  },
  closeProject(state){
    state.activeItems.project = {};
    router.push({name: "Projects"});
  },
  setAddress(state, payload) {
    state.activeItems.address = payload;
  },
  setDraft(state, payload) {
    state.activeItems.draft = payload;
  },
  setSepa(state, payload) {
    state.activeItems.sepa = payload;
  },

}

export const actions = {
  async setActiveCustomer({commit, getters}, customerId) {
    if(customerId !== null){
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    await axios.get(getters.getApiUrl + "customer/" + customerId, {headers}).then(async (r) => {
      if (r.data.result.data) {
        await commit("setActiveItem", {data: r.data.result.data, type: "customer"});
      }
    });} else {
      await commit("setActiveItem", {data: null, type: "customer"});
    }


    commit("setActiveItem", {data: null, type: "task"});
  },
  async setActiveDocument({commit, getters}, documentId) {
    if (documentId !== null) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

      if (typeof documentId === 'object') {

        documentId = documentId.id;
      }
      await axios.get(getters.getApiUrl + "document/" + documentId, {headers}).then(async (r) => {

        if (r.data.result.success) {
          await commit("setActiveItem", {data: r.data.result.data, type: "document"});
        }
      });
    } else {
      await commit("setActiveItem", {data: null, type: "document"});
    }
  },
  async setActiveProduct({commit, getters}, productId) {

    if (productId !== null) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      await axios.get(getters.getApiUrl + "product/" + productId, {headers}).then(async (r) => {
        if (r.data.result.success) {
          await commit("setActiveItem", {data: r.data.result.data, type: "product"});

        }
      });
    } else {
      await commit("setActiveItem", {data: null, type: "product"});
    }
  },
  async setActiveEvent({commit, getters}, eventId) {

    if (eventId !== null) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

      if (typeof eventId === 'object') {
        eventId = eventId.id;
      }
      await axios.get(getters.getApiUrl + "event/" + eventId, {headers}).then(async (r) => {

        if (r.data.result.success) {
          await commit("setActiveItem", {data: r.data.result.data, type: "event"});
        }
      });
    } else {
      await commit("setActiveItem", {data: null, type: "event"});
    }
  },
  setTask({commit}, task) {
    // Change incoming to setActiveTask when API ready
    if (task === null || task.id === null) {
      commit("setActiveItem", {data: null, type: "task"});
    } else {
      commit("setActiveItem", {data: task, type: "task"});
    }
  },
  setAboActive({commit}, abo) {
    commit("setActiveItem", {data: abo, type: "abo"});
  },
  setActiveDraft({commit, getters}, draft) {
    // todo-matt: Use when Api ready
    // if (draftId !== null) {
    //   const headers = getters.getAuthorization;
    //   await axios.get(getters.getApiUrl + "draft/" + draftId, {headers}).then(async (r) => {
    //     if (r.data.success) {
    //       await commit("setActiveItem", {data: r.data.data, type: "draft"});
    //     }
    //   });
    // } else {
    //   await commit("setActiveItem", {data: null, type: "draft"});
    // }

    commit("setActiveItem", {data: draft, type: "draft"});

  },
  setActiveCategory({commit}, category) {
    commit("setActiveItem", {data: category, type: "category"});
  },
  setActiveConfig({commit},  data) {
    commit("setActiveItem", data);
  },
  setActiveProject({commit}, project) {
    commit("setActiveItem", {data: project, type: "project"});
  }
}

export const getters = {
  customerActive: state => {
    return state.activeItems.customer;
  },
  employeeActive: state => {
    return state.activeItems.employee;
  },
  documentActive: state => {
    return state.activeItems.document;
  },
  productActive: state => {
    return state.activeItems.product;
  },
  draftActive: state => {
    return state.activeItems.draft;
  },
  projectActive:state => {
    return state.activeItems.project;
  },
  categoryActive: state => {
    return state.activeItems.category;
  },
  legalActive: state => {
    return state.activeItems.legal;
  },
  sepaActive: state => {
    return state.activeItems.sepa;
  },
  aboActive: state => {
    return state.activeItems.abo;
  },
  eventActive: state => {
    return state.activeItems.event;
  },
  getAddress: state => {
    return state.activeItems.address;
  },
  getConfigWorkflowTracker: state => {
    return state.activeItems.ConfigWorkflowTracker;
  },
  getConfigWorkflowPrio: state => {
    return state.activeItems.ConfigWorkflowPrio;
  },
  getConfigWorkflowStates: state => {
    return state.activeItems.ConfigWorkflowStates;
  },
  getConfigWorkflowActivities: state => {
    return state.activeItems.ConfigWorkflowActivities;
  },
  getConfigTimeConfigs: state => {
    return state.activeItems.ConfigTimeConfigs;
  },
  getConfigCustomerType: state => {
    return state.activeItems.ConfigCustomerType;
  },
  getActiveOverride: state => {
    return state.activeItems.ConfigLangOverride;
  },
  getConfigCustomerTitle: state => {
    return state.activeItems.ConfigCustomerTitle;
  },
  getConfigCustomerTags: state => {
    return state.activeItems.ConfigCustomerTags;
  },
  getConfigCustomerStructure: state => {
    return state.activeItems.ConfigCustomerStructure;
  },
  getConfigCustomerState: state => {
    return state.activeItems.ConfigCustomerState;
  },
  getConfigCustomerAcquisitions: state => {
    return state.activeItems.ConfigCustomerAcquisitions;
  },
  getConfigAccountingKeys: state => {
    return state.activeItems.ConfigAccountingKeys;
  },
  getConfigBankAccounts: state => {
    return state.activeItems.ConfigBankAccounts;
  },
  getConfigCostNumbers: state => {
    return state.activeItems.ConfigCostNumbers;
  },
  getConfigCustomerPrio: state => {
    return state.activeItems.ConfigCustomerPrio;
  },
  getConfigCustomerLead: state => {
    return state.activeItems.ConfigCustomerLead;
  },
  getConfigCountryConfigs: state => {
    return state.activeItems.ConfigCountryConfigs;
  },
  getConfigCustomerTaxes: state => {
    return state.activeItems.ConfigCustomerTaxes;
  },
  getConfigYearplan: state => {
    return state.activeItems.ConfigYearplan;
  },
}



