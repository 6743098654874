<template>
  <div class="card">
    <div class="card-header">
      <h6 style="display: inline-block;"><i class="fa-duotone fa-calendar-star"></i> Agenda</h6>
      <button class="btn-outline-primary btn-sm btn float-end" @click="addAgenda = true;"><i class="fa-duotone fa-plus"
      ></i> Agenda hinzufügen
      </button>
    </div>
    <div class="card-body">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Start</th>
          <th style="width: 170px;">Zeit</th>
          <th>Raum</th>
          <th>Titel</th>
          <th>Trainer</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="a in agenda" :class="(a._old === '1') ? 'old_agenda' : ''">
          <td>{{ $filters.formatDate(a.agenda_start) }}</td>
          <td>{{ $filters.formatTimeShort(a.agenda_start) }} - {{ $filters.formatTimeShort(a.agenda_end) }}</td>
          <td>{{ a.room }}</td>
          <td>{{ a.title }}</td>
          <td><span class="text-xs">{{ a.trainer }}</span></td>
          <td>
            <button class="btn btn-outline-primary btn-sm" @click="checkAgenda(a)" tooltip="Fragen überprüfen"><i
              class="fa-duotone fa-comments"></i></button>
          </td>
          <td>
            <button class="btn btn-outline-primary btn-sm" @click="editAgendaPop(a)" tooltip="Agenda bearbeiten"><i
              class="fa-duotone fa-edit"></i></button></td><td>
            <button class="btn btn-outline-secondary btn-sm" @click="deleteAgenda(a.id)" tooltip="Agenda löschen"><i
              class="fa-duotone fa-trash"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <Modal v-if="addAgenda || editAgenda">
    <div class="modal show " style="display: block;">
      <div class="modal-dialog modal-lg purpleView ">
        <div class="modal-content">
          <div class="modal-header">
            Neue Agenda
            <button type="button" class="btn btn-outline-primary btn-sm ml-auto" @click="postAgenda()"><i
              class="fa-duotone fa-floppy-disk"></i> Speichern
            </button>
            <button type="button" class="btn-close ms-3" @click="addAgenda = false;editAgenda = false;"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <label>Titel</label>
                <input type="text" class="form-control mb-2" placeholder="Titel" v-model="newAgenda.title">
                <label>Start</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss"
                               :placeholder="'Startzeitpunkt wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="newAgenda.agenda_start"></VueDatePicker>
                <label>Ende</label>
                <VueDatePicker model-type="yyyy-MM-dd HH:mm:ss" format="dd.MM.yyyy HH:mm:ss"
                               :placeholder="'Endzeitpunkt wählen'" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="true" cancel-text="X" select-text="Wählen"
                               v-model="newAgenda.agenda_end"></VueDatePicker>
                <label>Raum</label>
                <input type="text" class="form-control mb-2" v-model="newAgenda.room">
                <label>Trainer/Dozent</label>
                <input type="text" class="form-control mb-2" v-model="newAgenda.trainer">
                <label>Beschreibung</label>
                <textarea class="mb-2 form-control" v-model="newAgenda.description"></textarea>
                <label >Agenda Typ</label>
                <!-- yes no select -->
                <select class="form-select mb-2" v-model="newAgenda.agenda_type" @input="onAgendaTypeChange" >
                  <option value="agenda">Normal</option>
                  <option value="break">Pause</option>
                  <option value="workshop">Workshop</option>
                </select>
                <template v-if="newAgenda.agenda_type === 'workshop'">
                <label class="mt-2">Veranstaltungs-ID für Workshop</label>
                <input type="text" class="form-control mb-2" v-model="newAgenda.workshop_date_id">
                </template>
                <label class="mt-2">Fragen erlauben</label>
                <!-- yes no select -->
                <select class="form-select" v-model="newAgenda.allow_questions">
                  <option value="1">Ja</option>
                  <option value="0">Nein</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="showCheckAgenda" :class="(readonly) ? 'whiteModal' : '' ">
    <div class="modal show " style="display: block;">
      <div class="modal-dialog modal-xl purpleView ">
        <div class="modal-content">
          <div class="modal-header">
            Agenda Details
            <div class="btn-group ml-auto me-3">
            <button type="button" class="btn  btn-sm" :class="(!sortable) ? 'btn-secondary' : 'btn-outline-secondary'" @click="sortable = true"  v-if="!readonly && !sortable"><i class="fa-duotone fa-edit"></i> Bearbeiten</button>
            <button type="button" class="btn  btn-sm" :class="(sortable) ? 'btn-secondary' : 'btn-outline-secondary'" @click="sortable = false" v-if="!readonly && sortable"><i class="fa-duotone fa-spinner"></i> Sortieren</button>
            <button type="button" class="btn btn-outline-primary btn-sm" @click="toggleReadonly" v-if="!readonly"><i class="fa-duotone fa-book-reader"></i> Leseansicht</button>
            <button type="button" class="btn btn-outline-secondary btn-sm" @click="toggleReadonly" v-if="readonly"><i class="fa-duotone fa-edit"></i> Bearbeitung</button>
            <template v-if="singleAgenda.allow_questions === '1'">
              <button type="button" class="btn btn-outline-secondary btn-sm" @click="toggleQuestions" v-if="!readonly"><i class="fa-duotone fa-question-circle"></i> keine Fragen</button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-outline-primary btn-sm" @click="toggleQuestions" v-if="!readonly"><i class="fa-duotone fa-question-circle"></i> Fragen erlauben</button>
            </template>
            </div>
            <button type="button" class="btn-close ms-3" @click="showCheckAgenda = false"></button>
          </div>
          <div class="modal-body">
            <div class="row"  v-if="!readonly">
              <div class="col-12">
                <table class="table">
                  <thead>
                  <tr><th>Start</th>
                    <th style="width: 105px;">Zeit</th>
                    <th>Raum</th>
                    <th>Titel</th>
                    <th>Trainer</th></tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ $filters.formatDate(singleAgenda.agenda_start) }}</td>
                    <td>{{ $filters.formatTimeShort(singleAgenda.agenda_start) }} - {{ $filters.formatTimeShort(singleAgenda.agenda_end) }}</td>
                    <td>{{ singleAgenda.room }}</td>
                    <td>{{ singleAgenda.title }}</td>
                    <td><span class="text-xs">{{ singleAgenda.trainer }}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-for="(question, index) in singleAgenda.questions"
              :key="question.id"

            >
              </div>
            <div v-if="!sortable">


              <template v-for="element in singleAgenda.questions">
                <template v-if="(readonly && element.state === '1') || !readonly">
                  <div class="row mb-1 pb-1 readonlyRow">
                    <template v-if="readonly"><div class="col-9">{{element.question}}</div></template>
                    <template v-else>

                      <div class="col-9">
                        <textarea @blur="updateQuestion(element)"   class="form-control no-select" v-model="element.question"></textarea>
                      </div>
                    </template>

                    <div class="col-3">
                      <div class="btn-group float-end no-select" v-if="!readonly">
                        <button class="btn  btn-sm" :class="(element.state === '1') ? 'btn-primary' : 'btn-outline-primary'" @click="acceptQuestion(element)"><i
                          class="fa-duotone fa-check"></i></button>
                        <button class="btn btn-sm" :class="(element.state === '2') ? 'btn-secondary' : 'btn-outline-secondary'"  @click="denyQuestion(element)"><i class="fa-duotone fa-ban"></i></button>
                      </div>
                      <small><i class="fa-duotone fa-user"></i> {{element._author}}</small><br>
                      <small><i class="fa-duotone fa-timer"></i> {{$filters.formatDateTime(element.crm_created_date)}}</small></div>
                  </div>
                </template>

              </template>

            </div>
            <draggable v-if="sortable" v-model="singleAgenda.questions"
                       group="people"
                       @start="drag=true"
                       @end="drag=false;checkMove()"
                       item-key="id">
              <template #item="{element}">

                <template v-if="(readonly && element.state === '1') || !readonly">
                  <div class="row mb-1 pb-1 readonlyRow">
                    <template v-if="readonly"><div class="col-9">{{element.question}}</div></template>
                    <template v-else>

                      <div class="col-9">
                        <textarea @blur="updateQuestion(element)"   class="form-control no-select" v-model="element.question"></textarea>
                      </div>
                    </template>

                    <div class="col-3">

                      <small><i class="fa-duotone fa-user"></i> {{element._author}}</small><br>
                      <small><i class="fa-duotone fa-timer"></i> {{$filters.formatDateTime(element.crm_created_date)}}</small></div>
                  </div>
                </template>

              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
Pusher.logToConsole = false;
import draggable from 'vuedraggable'
let pusher;
let channel;
// import jquery
import $ from 'jquery';
export default {
  name: 'Agenda',
  data() {
    return {
      agenda: [],
      sortable: false,
      addAgenda: false,
      showCheckAgenda: false,
      singleAgenda: [],
      draggedIndex: null,
      editAgenda: false,
      newAgenda: {
        agenda_start: '',
        agenda_end: '',
        room: '',
        title: '',
        trainer: '',
        description: '',
        allow_questions: 1
      },
      readonly: false
    }
  },
  components: {VueDatePicker, draggable},
  computed: {
    ...mapGetters(["modalsList", "eventActive"])
  },
  methods: {
    checkMove() {
      console.log("moved", this.singleAgenda.questions)

      // set sortNumber with key +10
      let q;
      for(q in this.singleAgenda.questions) {
        this.singleAgenda.questions[q].sortNumber = q;
      }
      this.updateAgenda();
      this.getAgenda();

    },
    getAgenda() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/agenda", {headers: headers})
        .then((response) => {
          this.agenda = response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleQuestions(){
      this.singleAgenda.allow_questions = (this.singleAgenda.allow_questions === '1') ? '0' : '1';
      this.updateAgenda();
      this.getAgenda();

    },
    toggleReadonly() {
      this.readonly = !this.readonly;
    },
    checkAgenda(agenda) {
      this.showCheckAgenda = true;
      this.getSingleAgenda(agenda.id);
    },
    getSingleAgenda(id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/agenda/"+id , {headers: headers})
        .then((response) => {
          this.singleAgenda =  response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    drop(index) {
      if (this.draggedIndex !== null) {
        const draggedQuestion = this.singleAgenda.questions[this.draggedIndex];

        // Rearrange the questions array
        this.singleAgenda.questions.splice(this.draggedIndex, 1);
        this.singleAgenda.questions.splice(index, 0, draggedQuestion);

        // Update sortNumber values based on the new order
        this.singleAgenda.questions.forEach((question, newIndex) => {
          question.sortNumber = newIndex + 1;
        });
        this.updateQuestion(this.singleAgenda.questions[index]);
        this.draggedIndex = null;
      }
    },
    dragStart(index) {
      this.draggedIndex = index;
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragEnd() {
      // Re-render the list after the drag operation ends
      this.$forceUpdate();
    },

    postAgenda() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if(this.editAgenda && this.newAgenda.id){
        axios.put(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/agenda/" + this.newAgenda.id, this.newAgenda, {headers: headers})
          .then((response) => {
            this.getAgenda();
            this.editAgenda = false;
            this.$store.dispatch('toastSuccess', "Agenda erfolgreich bearbeitet.");
          })
          .catch((error) => {
            this.$store.dispatch("handleApiResponse", error.response)
          });
        return;
      }
      axios.post(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/agenda", this.newAgenda, {headers: headers})
        .then((response) => {
          this.getAgenda();
          this.addAgenda = false;
          this.$store.dispatch('toastSuccess', "Agenda erfolgreich angelegt.");

        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response)
        });
    },
    editAgendaPop(agenda) {
      this.editAgenda = !this.editAgenda;
      this.newAgenda = agenda;
    },
    acceptQuestion(question){
      question.state = '1';
      this.updateQuestion(question);
    },
    denyQuestion(question){
      question.state = '2';
      this.updateQuestion(question);
    },
    onAgendaTypeChange() {
      if (this.newAgenda.agenda_type !== 'workshop') {
        this.newAgenda.workshop_date_id = 0;
      }
    },
    updateQuestion(question){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let questionDetails = this.singleAgenda.questions;
      axios.put(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/agenda/" + this.singleAgenda.id + "/option/" + question.id, questionDetails, {headers: headers})
        .then((response) => {
          this.getAgenda();

        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response)
        });
    },
    updateAgenda(){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/agenda/" + this.singleAgenda.id, this.singleAgenda, {headers: headers})
        .then((response) => {
          this.getAgenda();
          this.addAgenda = false;
        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response)
        });
    },
    deleteAgenda(id) {
      this.$confirm('Soll die Agenda wirklich gelöscht werden?', 'Agenda löschen', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/agenda/" + id, {headers: headers})
          .then((response) => {
            this.getAgenda();
            this.addAgenda = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }).catch(() => {
        console.log('error')
      });
    }
  },
  created() {
    this.getAgenda();
  },
  mounted() {

    pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
    channel = pusher.subscribe(this.$store.state.apiToken);

  },

  watch: {
    singleAgenda(newV, oldV) {
      let VueInner = this;
      channel.unbind("agenda-" + oldV.id);
      console.log('bind old ', oldV.id);
      console.log('bind new ', newV.id);
      channel.bind('agenda-' + this.singleAgenda.id, function (data) {

        VueInner.getSingleAgenda(VueInner.singleAgenda.id);

      });
    },
    readonly() {





    }
  }
}
</script>
<style lang="scss">
body {
  .vue-universal-modal.whiteModal {
    background: #333;

  }
}

tr.old_agenda {
  opacity: .5;
}
.readonlyRow{
  border-bottom: 1px solid #eee;
}
.whiteModal .modal-body .readonlyRow {
  /* font-size: 22px; */
  margin-bottom: 24px !important;
  /* display: inline-block; */
  float: left;
  background: #eee;
  padding: 24px !important;
}

.whiteModal .modal-body .readonlyRow .col-9 {
  font-size: 20px;
  line-height: 32px;
}
</style>
