<template>

  <div class="container-fluid"
       v-if="!getUser.acl || (getUser && getUser.acl && getUser.acl.dashboard && getUser.acl.dashboard.read === 'yes' && getUser.acl.documents.umsatzs === 'yes') && getUser && getUser.dashboardWidgets && getUser.dashboardWidgets.length > 0 && customDash && customDash.sorting && customDash.sorting.components">
    <div class="row">
      <div v-for="chart in customDash.sorting.components" class="col-12 mb-4" :class="'col-lg-12'"><!--+chart.grid-->
        <component :is="chart.type" :options="chart" :filter="filter" v-if="chart.type"/>
      </div>
    </div>
    <div class="row" v-if="getMenu && getMenu.customers">
      <div class="col-lg-12 mb-lg">
        <filterMonthYear @filter-month-year="updateFilter($event, 'filter_monthYear')"/>
      </div>
    </div>
  </div>
  <div class="container-fluid " v-else>
    <div class="row flex">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="card" style="border: 0;">
              <div class="card-body">
                <img src="/img/sketch.connect_intro.gif"
                     style="display: block; margin-left: auto; margin-right: auto; margin-bottom: 24px;margin-top: 24px;"/>
                <p class="text-center">Das Dashboard kann pro User frei konfiguriert werden.
                  <br/>
                  <br/>
                  <button class="btn btn-outline-primary btn-sm" @click="$store.commit('openModal', 'widget');"><i class="fa-duotone fa-toolbox"></i> Konfigurieren</button> oder via Button oben rechts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 pt-4 mb-4 pb-4">&nbsp;</div>
  </div>
</template>
<script>
import axios from "axios";
import {Chart, registerables} from 'chart.js';
import {mapGetters, mapActions} from "vuex";
import filterMonthYear from "@/routing/core/Dashboard/statistic/FilterMonthYear";
import widgetDocuments from "@/routing/core/Dashboard/widgets/documents";
import widgetTurnover from "@/routing/core/Dashboard/widgets/turnover";
import widgetAcquisition from "@/routing/core/Dashboard/widgets/acquisition";
import widgetConversion from "@/routing/core/Dashboard/widgets/conversions";
import widgetCustomer from "@/routing/core/Dashboard/widgets/customer";
import widgetContacts from "@/routing/core/Dashboard/widgets/contacts";
import widgetLeads from "@/routing/core/Dashboard/widgets/leads";
import widgetCountry from "@/routing/core/Dashboard/widgets/country";
import widgetTasksWeekly from "@/routing/core/Dashboard/widgets/tasksWeekly";
import widgetContactRequests from "@/routing/core/Dashboard/widgets/contactRequests";
import widgetProjectTurnover from "@/routing/core/Dashboard/widgets/projectTurnover";
import widgetSales from "@/routing/core/Dashboard/widgets/sales";
Chart.register(...registerables);
export default {
  name: "dashboard-default",
  data() {
    return {
      customDash: {
        sorting: {
          components: [],
        }
      },
      options: {
        height: '300px',
        size: 0
      },
      filters: {
        filter_monthYear: {
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        },
      },
      filter: '',
    }
  },
  components: {
    filterMonthYear,
    widgetDocuments,
    widgetTurnover,
    widgetAcquisition,
    widgetConversion,
    widgetCustomer,
    widgetContacts,
    widgetLeads,
    widgetCountry,
    widgetTasksWeekly,
    widgetContactRequests,
    widgetProjectTurnover,
    widgetSales
  },
  methods: {
    ...mapActions(["getPlugins"]),
    updateFilter(date, type) {
      this.filters[type] = date;
      this.filters = {...this.filters};
      this.filter = '';
      if (this.filters.filter_monthYear.month && this.filters.filter_monthYear.year) {
        this.filter = '?month=' + this.filters.filter_monthYear.month + '&year=' + this.filters.filter_monthYear.year;
      }
    },
  },
  computed: {
    ...mapGetters(["getMenu", "getUser"])
  },
  created() {
    if (this.getUser) {
      this.customDash.sorting.components = this.getUser.dashboardWidgets;
    }
  }
};
</script>
<style>
.Dashboard table tbody tr:last-child td {
  border-bottom: 0;
}

.monthFilterWrap {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  box-shadow: 0 0 10px #ccc;
  background: #f8f8f8;
  padding: 12px;
}

.Dashboard .ps > table {
  margin-bottom: 0;
}
</style>


