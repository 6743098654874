<template>
  <div class="filesystemFullWrap">
    <div class="card" style="border-radius: 0;">
      <div class="card-header">
        <button class="btn btn-outline-primary btn-sm me-2" @click="refreshFiles"><i
          class="fa-duotone fa-file-archive"></i>
          Home
        </button>
        <button class="btn btn-outline-secondary btn-sm" @click="navigateHash('invoices')"><i
          class="fa-duotone fa-folder-bookmark"></i> Rechnungen
        </button>
      </div>
      <div class="card-body" v-if="!loading">
        <div class="folders" v-if="filesystem">
          <template v-for="singleFolder in filesystem">
            <div class=" documentbox category" @click="navigateHash(singleFolder.hash)">

                  <span class="icon">
                  <template v-if="singleFolder.icon === 'icon-folder'"><i
                    class="fa-duotone fa-folder"></i><span class="sumItems">{{
                      singleFolder._sumItems
                    }}</span></template></span>
              <span class="text">
                     {{ singleFolder.title }}
                      </span>
            </div>
          </template>
        </div>
        <div class="files documentLineWrap">
          <template v-if="fileitems.length > 0">
            <div class=" documentline lineitems file">
              <div class="lineitem l-number">
                #
              </div>
              <div class="lineitem l-title">Titel</div>
              <div class="lineitem l-date">Hochgeladen</div>
              <div class="lineitem l-date">Belegdatum</div>
              <template v-if="activeHash ==='invoices'">
                <div class="lineitem l-type">Typ</div>
                <div class="lineitem l-account">Konto</div>
                <div class="lineitem l-type">Stufe</div>
                <div class="lineitem l-type">Preis</div>
                <div class="lineitem l-type">Offen</div>
              </template>
              <div class="lineitem l-action"></div>
            </div>
          </template>
          <template v-for="singleFile in fileitems">
            <div class=" documentline lineitems file" @click="navigateHash(singleFile.hash)"
                 :class="(singleFile._opos && singleFile._opos.partPaymentClasses) ? singleFile._opos.partPaymentClasses : ''">
              <div class="lineitem l-number">
                {{ singleFile.id }}
              </div>
              <div class="lineitem l-title" @click="showFile(singleFile)">{{ singleFile.title }}
                <template v-if="singleFile.customer_id">
                  <br>
                  <small>
                    <template v-if="singleFile.c_company">
                      {{ singleFile.c_company }} -
                    </template>
                    {{ singleFile.c_firstname }} {{ singleFile.c_lastname }}</small>
                </template>
                <template
                  v-if="singleFile._opos && singleFile._opos.partPaymentClasses && singleFile._opos.partPaymentClasses === 'type_crm storno'">
                  &nbsp;<span tooltip="Storno" style="color: #b21f28;" position="right"><i
                  class="fa-duotone fa-rectangle-xmark"></i></span>
                </template>
              </div>
              <div class="lineitem l-date">{{ $filters.formatDate(singleFile.uploaded_on) }}</div>
              <div class="lineitem l-date">
                <template v-if="activeHash ==='invoices'"><span tooltip="Rechnungsdatum" position="left">{{
                    $filters.formatDate(singleFile.invoice_date)
                  }}</span><br><span tooltip="Zahlungsziel" position="left">{{
                    $filters.formatDate(singleFile.pay_due)
                  }}</span></template>
                <template v-else>{{ $filters.formatDate(singleFile.doc_date) }}</template>
              </div>
              <template v-if="activeHash ==='invoices'">
                <div class="lineitem l-type">
                  <template v-if="singleFile.type==='crm'">
                    <span tooltip="Ausgehende Rechnung (CRM)" style="color: #3B8130;">
                    <i class="fa-duotone fa-arrow-right"></i></span>
                  </template>
                  <template v-if="singleFile.type==='opo'">
                    <span tooltip="Fehlende Rechnung" style="color: #b21f28;">
                    <i class="fa-duotone fa-file-excel"></i></span>
                  </template>
                  <template v-if="singleFile.type==='out'">
                    <span tooltip="Ausgehende Rechnung (extern)" style="color: #3B8130;">
                    <i class="fa-duotone fa-arrow-alt-left"></i></span>
                  </template>
                  <template v-if="singleFile.type==='in'">
                    <span tooltip="Eingehende Rechnung">
                    <i class="fa-duotone fa-arrow-alt-left"></i></span>
                  </template>
                </div>
                <div class="lineitem l-account content">
                  {{ singleFile._accountTitle }}<br>{{ singleFile.accounting_year }} -
                  {{ $t('accountingPeriods.' + singleFile.accounting_period) }}
                </div>
                <div class="lineitem l-type">{{ singleFile.mahnstufe }}</div>
                <div class="lineitem l-type">{{ $filters.priceEuro(singleFile.price) }}</div>
                <div class="lineitem l-type">{{ $filters.priceEuro(singleFile.balance) }}</div>
              </template>
              <div class="lineitem l-action ml-auto">
                <button type="button" tooltip="Bearbeiten" @click="showFile(singleFile)"
                        class="btn btn-outline-primary btn-sm"><i
                  class="fa-duotone fa-edit"></i></button>
                <button type="button" class="btn btn-outline-primary btn-sm ms-1" @click="deleteFile(singleFile)"
                        tooltip="Löschen"><i class="fa-duotone fa-trash"></i></button>
                <template v-if="activeHash ==='invoices'">
                  <button type="button" tooltip="Zahlung erfassen" @click="showFile(singleFile)"
                          class="btn btn-outline-primary btn-sm ms-1"><i
                    class="fa-duotone fa-wallet"></i></button>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div class="paginationWrapper mb-3">
          <ul class="pagination d-flex justify-content-center mt-3">
            <li class="page-item" :class="{disabled: (offset === 0)}">
              <template v-if="offset === 0">
                <button class="page-link disabled" href="#" aria-label="Previous">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
                </button>
              </template>
              <template v-else>
                <button @click="setPage('previous')" class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
                </button>
              </template>
            </li>
            <li class="page-item"
                :class="{disabled: ((offset + limit) >= complete || complete < limit)}">
              <template v-if="((offset + limit) >= complete || complete < limit)">
                <button class="page-link" aria-label="Next">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
                </button>
              </template>
              <template v-else>
                <button class="page-link" @click="setPage('next')" aria-label="Next">
                  <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
                </button>
              </template>
            </li>
          </ul>
          <template v-if="activeHash">
            <div class="overviewCounter float-end mb-3">
      <span v-if="complete > 0">
  Einträge: {{ offset + 1 }} -
  {{ Math.min(offset + limit, complete) }}
  von {{ complete }}
</span>
              <span v-else>Keine Einträge</span>
            </div>
            <div class="limitBox float-start mb-3">
              <Multiselect v-model="limit" :options="listLimits" v-if="limit"
                           :multiple="false"
                           :canClear="false"
                           open-direction="top"
                           :closeOnSelect="true"
                           :placeholder="'Anzahl wählen'"
                           openDirection="top"
                           @update:model-value="offset = 0; navigateHash(activeHash)"
                           class="form-select small">
              </Multiselect>
            </div>
          </template>
        </div>
      </div>
      <div class="card-body" style="height: 300px;" v-else>
        <Loading :loading="loading" height="auto"/>
      </div>
    </div>
  </div>
  <Modal v-if="modalsList.filesystem" class="wrapper Files">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="card">
            <h6 class="card-header">{{ fileActive.title }}
              <button type="button" class="btn btn-close float-end ms-4"
                      @click="$store.commit('closeModal', 'filesystem'); fileUrl = null;">
              </button>
            </h6>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-12">
                  <template v-if="activeHash === 'invoices'">
                    <div class="form-group">
                      <label>Betrag</label>
                      <input type="number" v-model="fileActive.price" step="0.01">
                    </div>
                    <div class="form-group">
                      <label>Buchungsdatum</label>
                      <VueDatePicker v-model="fileActive.pay_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                     placeholder="Buchungsdatum" :auto-apply="true"
                                     :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                                     cancel-text="X" select-text="Wählen" :teleport="true"
                                     class="small"
                      />
                    </div>
                    <div class="form-group">
                      <label>Konto</label>
                      <Multiselect class="small" v-if="onlyfolders"
                                   :options="getConfigs.bankAccounts"
                                   :multiple="false"
                                   label="title"
                                   :internal-search="true"
                                   :close-on-select="true"
                                   :clean-clear="true"
                                   :clear-on-search="true"
                                   :show-labels="false"
                                   v-model="fileActive.account_id"
                                   :valueProp="'id'"
                                   :searchable="true"
                                   :closeOnSelect="true"
                                   :noOptionsText="$t('filters.noOptionsText')"/>
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn btn-outline-primary" @click="putInvoiceFile(fileActive)"><i
                        class="fa-duotone fa-floppy-disk"></i> Speichern
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="fileActive.title">
                    </div>
                    <div class="form-group">
                      <VueDatePicker v-model="fileActive.doc_date" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                     placeholder="Belegdatum / Eingangsdatum" :auto-apply="true"
                                     :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                                     cancel-text="X" select-text="Wählen" :teleport="true"
                                     class="small"
                      />
                    </div>
                    <div class="form-group">
                      <Multiselect class="small" v-if="onlyfolders"
                                   :options="onlyfolders"
                                   :multiple="false"
                                   label="title"
                                   :internal-search="true"
                                   :close-on-select="true"
                                   :clean-clear="true"
                                   :clear-on-search="true"
                                   :show-labels="false"
                                   v-model="fileActive.folder"
                                   :valueProp="'id'"
                                   :searchable="true"
                                   :closeOnSelect="true"
                                   :noOptionsText="$t('filters.noOptionsText')"/>
                    </div>
                    <div class="form-group">
                      <input type="text" v-model="fileActive.new_folder" class="form-control"
                             placeholder="Neuer Ordner"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <input type="checkbox" class="form-check-input" v-model="fileActive.visibility"
                             id="invoiceVisible"><label
                      for="invoiceVisible"> Für Kunden sichtbar?</label>
                    </div>
                    <div class="form-group" v-if="fileActive.customer_id">
                      Zugewiesen an: {{ fileActive.customer_id }}
                    </div>
                    <div class="form-check form-check-inline" v-if="fileActive.customer_id">
                      <input type="checkbox" class="form-check-input" v-model="assignCustomer" id="assignCustomer">
                      <label
                        for="assignCustomer">Kundenzuweisung ändern</label>
                    </div>
                    <div class="form-group">
                      <customersearch v-if="assignCustomer || !fileActive.customer_id" v-model="fileActive.customer_id"
                                      @changed-customer="handleCustomerChange"/>
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn btn-outline-primary" @click="putFile(fileActive)"><i
                        class="fa-duotone fa-floppy-disk"></i> Speichern
                      </button>
                    </div>
                  </template>
                </div>
                <div class="col-12 col-lg-8" style="position: relative;">
                  <Loading v-if="loadingFile" :loading="loadingFile" type="inner" height="350px" />
                  <template v-else>
                    <template v-if="fileMime === 'image/jpeg' || fileMime === 'image/png' || fileMime === 'image/svg' ">
                      <img :src="'data:'+fileMime+';base64,'+fileEncoded"
                           style="width: 100%; display: block; height: auto;"/>
                    </template>
                    <template v-else>
                      <iframe v-if="fileUrl" :src="fileUrl" width="100%" height="650px"></iframe>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import customersearch from "@/filter/components/customersearch.vue";
import Multiselect from '@vueform/multiselect'
import Loading from "@/components/Loading.vue";

export default {
  name: 'Filesystem',
  data() {
    return {
      loading: true,
      loadingFile: true,
      percent: 0,
      assignCustomer: false,
      type: 'full',
      activeHash: false,
      filesystem: [],
      onlyfolders: [],
      fileitems: [],
      fileActive: null,
      fileEncoded: false,
      fileMime: null,
      fileUrl: null,
      offset: 0,
      complete: 0,
      listLimits: [5, 10, 15, 20, 50, 100],
      limit: 20,
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  computed: {
    ...mapGetters(["modalsList", "getConfigs"])
  },
  components: {
    Loading,
    VueDatePicker, Multiselect, customersearch,
  },
  methods: {
    refreshFiles() {
      this.loading = true;
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'documentBox/search?limit=' + this.limit + '&offset=' + this.offset, {headers}).then(response => {
        this.filesystem = response.data.result.data;
        this.fileitems = response.data.result.files;
        this.complete = response.data.result.complete;
        this.loading = false;
      }).catch((error) => {
        this.$store.dispatch('handleApiResponse', error.response)
      });
    },
    onlyFolders() {
      this.loading = true;
      let headers = this.headers;
      // todo-matt: api call for only folders
      axios.get(this.$store.state.apiUrl + 'documentBox/search', {headers}).then(response => {
        this.onlyfolders = response.data.result.data;
        this.complete = response.data.result.complete;
        this.loading = false;
      }).catch((error) => {
        this.$store.dispatch('handleApiResponse', error.response)
      });
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    navigateFolder(folder) {
      console.log(folder);
    },
    showFile(singleFile) {
      this.$store.commit('openModal', 'filesystem');
      this.fileActive = singleFile;
      this.loadingFile = true;
      this.getFileDetails(singleFile);
      this.getFilePreview(singleFile);
    },
    navigateHash(hash) {
      this.loading = true;
      let headers = this.headers;
      this.activeHash = hash;
      axios.get(this.$store.state.apiUrl + 'documentBox/search/' + hash + '?limit=' + this.limit + '&offset=' + this.offset, {headers}).then(response => {
        this.filesystem = response.data.result.data;
        this.fileitems = response.data.result.files;
        this.complete = response.data.result.complete;
        this.loading = false;
      }).catch((error) => {
        this.$store.dispatch('handleApiResponse', error.response)
      });
    },
    deleteFile(singleFile) {
      let headers = this.headers;
      let VueInner = this;
      this.$confirm("Datei löschen", 'Sicher löschen?', 'warning', {
        confirmButtonText: this.$t("globals.yes"),
        cancelButtonText: this.$t("globals.no"),
        type: 'warning',
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + 'documentBox/' + singleFile.id, {headers}).then(response => {
          VueInner.navigateHash(VueInner.activeHash);
        }).catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response)
        });
      });
    },
    handleCustomerChange(customer) {
      this.fileActive.customer_id = customer.id;
    },
    getFileDetails(singleFile) {
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'documentBox/' + singleFile.id, {headers}).then(response => {
        this.fileActive = response.data.result.data;
        this.fileInfos = response.data;
      });
    },
    getFilePreview(singleFile) {
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'documentBox/' + singleFile.id + '/preview', {headers}).then(response => {
        this.fileEncoded = response.data.result.data;
        const base64Response = response.data.result.data;
        this.fileMime = response.data.result.mime;
        const mime = response.data.result.mime; // MIME type from the API response
        const byteCharacters = atob(base64Response);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: mime });
        this.fileUrl = URL.createObjectURL(blob); // Create a URL for the Blob
        this.loadingFile = false;
      });
    },
    putFile(singleFile) {
      let headers = this.headers;
      let postContent = this.fileActive;
      let VueInner = this;
      axios.put(this.$store.state.apiUrl + 'documentBox/' + this.fileActive.id, postContent, {headers}).then(response => {
        VueInner.navigateHash(VueInner.activeHash);
        VueInner.$store.commit('closeModal', 'filesystem');
      }).catch((error) => {
        VueInner.$store.dispatch('handleApiResponse', error.response)
      });
    },
    putInvoiceFile(singleFile) {
      let headers = this.headers;
      let postContent = this.fileActive;
      let VueInner = this;
      axios.put(this.$store.state.apiUrl + 'documentBox/invoices/' + this.fileActive.id, postContent, {headers}).then(response => {
        VueInner.navigateHash(VueInner.activeHash);
        VueInner.$store.commit('closeModal', 'filesystem');
      }).catch((error) => {
        VueInner.$store.dispatch('handleApiResponse', error.response)
      });
    },
    setPage(direction) {
      let type;
      type = direction;
      if (type === 'next' && this.offset <= this.complete) {
        this.offset = parseInt(this.offset) + parseInt(this.limit);
      }
      if (type === 'previous' && this.offset >= this.limit) {
        this.offset = parseInt(this.offset) - parseInt(this.limit);
      }
      this.navigateHash(this.activeHash);
    },
  },
  created() {
    this.refreshFiles();
    this.onlyFolders();
    window.addEventListener('triggerReloadFiles', this.refreshFiles);
  },
  beforeUnmount() {
    window.removeEventListener('triggerReloadFiles', this.refreshFiles);
  }
}
</script>

