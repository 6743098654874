
<template>
  <Multiselect class="small isMultiselect" v-if="filter.source !=='tbd' && options"


               v-model="$store.state.filters[type][filter.name].array"
               :options="getConfigs[filter.source]"
               :searchable="true"
               :multiple="true"
               :label="filter.labelType"
               :valueProp="'id'"
               :closeOnSelect="false"
               :placeholder="$t('filters.'+[filter.name])"
               @update:model-value="refreshList"
               mode="tags" ></Multiselect>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import Multiselect from '@vueform/multiselect'
import {mapGetters} from 'vuex';
export default {
  name: "multilist",
  data() {
    return {
      customers: "",
      options: [],
    };
  },
  components: {
    VueMultiselect, Multiselect
  },
  props: {
    type: {
      type: String,
      required: true
    },
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConfigs"]),
  },
  methods:{
    refreshList() {
      this.$store.state.lists[this.type].offset = 0;
      this.$store.dispatch(this.type+'Filter');
    },
    clearSelection() {
      this.$store.state.filters[this.type][this.filter.name].value = null;
      this.refreshList();
    }
  },
  created() {
    this.options = this.getConfigs[this.filter.source];
  }
}
</script>

