<template>
  <div class="appView config purpleView">
    <div class="productItem activeProduct" >
      <div class="card">
        <h6 class="card-header ">Globale Einstellungen
          <div class="btn-group float-end">
          <button @click="saveConfig"
                  type="button" class=" btn btn-sm btn-outline-primary">
            <i class="fa-duotone fa-floppy-disk"></i> Speichern
          </button></div>


        </h6>
        <ul class="tabNav">
          <li v-for="cat in configTabs" :key="cat.name"
              @click="changeTab(cat.name)"
              :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone" :class="cat.icon"></i><br><small>{{ cat.label }}</small>
          </li>
        </ul>
        <div class="card-body" v-if="activeSubtab === 'general'">
          <div class="form-group">
            <div class="form-group">
              <div class="btn-group me-3">
                <input type="radio" value="1" class="btn-check" id="issues_active_frontend-yes" autocomplete="off"
                       v-model.number="config.issues_active_frontend">
                <label class="btn btn-outline-primary" for="issues_active_frontend-yes">Ja</label>
                <input type="radio" value="0" class="btn-check" id="issues_active_frontend-no" autocomplete="off"
                       v-model.number="config.issues_active_frontend">
                <label class="btn btn-outline-primary" for="issues_active_frontend-no">Nein</label>
              </div>
              <label>Ticket System verwenden</label>
            </div>
          </div>
          <div class="row" v-if="config.issues_active_frontend === '1'">
            <div class="col-12">
              <div class="form-group">
                <label for="mailfrom">Link zum Frontend</label>
                <input id="mailfrom" v-model="config.issue_link" placeholder="https://sketch-connect.de/tasks/" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="mailfrom">Link zum Administrationsbereich</label>
                <input id="mailfrom" v-model="config.issue_link_admin" placeholder="https://admin.sketch-connect.de/issues/" type="text" class="form-control"/>
              </div>
            </div>
            <div class="hint">
              <p>Möchtest du einen eigenen E-Mail Verteiler für das Ticket-System benutzen, so kannst du dafür einen separaten Mitarbeiter anlegen!</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Editor2 from "@/components/Editor2";
import axios   from "axios";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";

export default {
  name: "GlobalConfig",
  data() {
    return {
      activeSubtab: 'general',
      configTabs: [
        {name: 'general', label: "Global", icon: 'fa-cog'},

      ],
      config: {}
    }
  },
  components: {
    Editor2
  },
  methods: {
    getConfigLocal() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let getUrl = this.$store.getters.getApiUrl + "config/params/values";

      getUrl = getUrl + "?limit=0";
      axios.get(getUrl, {headers}).then(async (response) => {
        let shortResponse = response.data.result;
        let key = 'params_values';

        await IndexedDbFunctions.addObject(this.$store.state.dbName, this.$store.state.dbVersion, 'config', {
          id: key,
          data: shortResponse.data
        });
        this.config = shortResponse.data;
      }).catch(function () {
      });
    },
    changeTab(tab) {
      this.activeSubtab = tab;
    },
    saveConfig(){
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.put(this.$store.state.apiUrl + "config/params", this.config, {headers}).then((r) => {
          let key = 'params_values';
          VueInner.$store.dispatch('toastSuccess', 'Einstellung wurde erfolgreich gespeichert');
          IndexedDbFunctions.addObject(this.$store.state.dbName, this.$store.state.dbVersion, 'config', {
            id: key,
            data: r.data.result.data
          });
        this.config = r.data.result.data;
          this.$router.push({name: 'ConfigWorkflowGlobal'});
    }).catch((error) => {
      this.$store.dispatch("handleApiResponse", error.response);
          this.$router.push({name: 'ConfigWorkflowGlobal'});
    });


    }
  },

  beforeMount() {


   this.getConfigLocal();

  }
}
</script>

