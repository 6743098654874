<template>
  <div
    v-show="this.$store.state.layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-grey'}`"
  />
  <aside
    class="my-3 border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`${
      this.$store.state.isRTL
        ? 'me-3 rotate-caret fixed-end'
        : 'fixed-start ms-3'
    }
    ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.sidebar ? 'closed' : ''}`"
    id="sidenav-main"
  >
    <div class="sidenav-header" v-if="!this.$store.state.sidebar">
      <div class="topMainIcons blue">

        <div class="actionIcon double center">
          <span class="innerFirst" :class="(filter === 'menu') ? 'active' : ''" @click="filter = 'menu'">
          <i class="fa-duotone fa-bars"></i>
          </span>
          <span class="innerLast" :class="(filter === 'filter') ? 'active' : ''" @click="filter = 'filter'">
            <i class="fa-duotone fa-filter"></i><template v-if="filter_count && filter_count > 0"><span class="indicator">{{filter_count}}</span></template>
          </span>
        </div>

        <div class="actionIcon single outter">
          <button @click="toggleSidebar" type="button" class="btn btn-toggler-sidebar"><i class="fa-duotone fa-chevron-left"></i></button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="actionIcon single outter">
        <button @click="toggleSidebar" type="button" class="btn btn-toggler-sidebar"><i class="fa-duotone fa-bars"></i><i class="fa-duotone fa-chevron-right"></i></button>
      </div>
    </div>
    <template v-if="filter ==='menu'">
      <sidenav-list :cardBg="custom_class"/>
    </template>
    <template v-else>
      <sidenav-filter :cardBg="custom_class"/>
    </template>
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import SidenavFilter from "./SidenavFilter.vue";
import logo from "@/assets/img/logo.png";
import logoWhite from "@/assets/img/logo-white.png";

export default {
  name: "index",
  components: {
    SidenavList, SidenavFilter
  },
  data() {
    return {
      logo,
      logoWhite,
      filter: "menu",
      sidebarToggle: false,
    };
  },
computed:{
  filter_count() {
    return this.$store.getters.getNumberOfActiveFilters;
  }
},
  methods: {
    toggleSidebar() {
      this.filter = "menu";
      this.$store.commit("toggleSidebar");
    },

  },
  props: ["custom_class", "layout"],


};
</script>
