<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
         <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{$t("customerPrio.details.newPrio")}}</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeConfigCustomerPrio');$store.commit('closeModal', 'ConfigCustomerPrio');">
        </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
            class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
          </button>

          <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
            class="fa-duotone fa-trash"></i> {{$t("globals.delete")}}
          </button>
      </div>



      <div class="card-body">

        <div class="row">
          <div class="col-3">
            <div class="form-group">
              <label> {{$t("customerPrio.details.iconList")}}</label>
              <br>
              <a href="https://fontawesome.com/search?o=r&s=duotone" target="_blank">
                Font Awesome Duotone</a>
            </div>
          </div>


          <div class="col-3">
            <div class="form-group">
              <label>{{$t("globals.name")}}</label>
              <input class="form-control" type="text" maxlength="1" v-model="d.name">
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label>{{$t("globals.color")}}</label>
              <input class="form-control" type="color" v-model="d.color">
            </div>
          </div>

          <div class="row">


            <div class="col-3">
              <div class="form-group">
                <label>{{$t("globals.sort")}}</label>
                <input class="form-control" type="number" v-model="d.ordering"  step="1">
              </div>
            </div>

            <div class="col-3">
              <div class="form-group">
                <label>{{$t("globals.standard")}}</label>
                <select   v-model="d.type" class="form-select" >
                  <option value="1" selected="selected">{{$t("globals.yes")}}</option>
                  <option value="0">{{$t("globals.no")}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Basic/CustomerPrio/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "CustomerPrioDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigCustomerPrio", "getConfigs"])
  },
  data() {
    return {
      d: {},
      isNew: true
    };
  },
  methods: {


    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/customerPrio/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess

          // Load Products

          this.$store.dispatch("loadConfig", {type: "customerPrio", percent: 45, message: 'Ihre Geduld wird auf die Probe gestellt'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Priorität wurde erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCustomerPrio');
          });

        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
      else {
        axios.post(this.$store.state.apiUrl + "config/customerPrio/", VueInner.d, {headers}).then(() => {



          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "customerPrio", percent: 45, message: 'Ihre Geduld wird auf die Probe gestellt'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigCustomerPrio');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Prioität wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigCustomerPrio');
          });


        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll die Priorität wirklich gelöscht werden?', 'Einstellung löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "config/customerPrio/" + VueInner.d.id, {headers}).then(() => {
          // close

          // dispatch toastSuccess
          this.$store.dispatch("loadConfig",{type: "customerPrio", percent: 45, message: 'Ihre Geduld wird auf die Probe gestellt'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Prioität wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCustomerPrio');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
        this.d = this.getConfigCustomerPrio

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>
