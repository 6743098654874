<template>
  <div class="btn-group me-3 ms-3"
       v-if="((taskActive && taskActive.id) || (getTimer && getTimer.task && getTimer.task.id))">
    <template v-if="!getTimer.task">
      <button @click="$store.dispatch('timerStart', taskActive)" class="btn btn-outline-primary purple btn-sm"
              tooltip="Task: Timer starten" position="left">
        <i class="fa-duotone fa-stopwatch"></i>
      </button>
    </template>
    <template v-else>
      <template v-if="getTimer.paused">
        <div class="btn btn-outline-secondary btn-sm" position="bottom"
             :tooltip="'#'+getTimer.task.id+': '+getTimer.task.title">{{ getTimer.formatted }}
        </div>
      </template>
      <template v-else>
        <div class="btn btn-outline-primary btn-sm" position="bottom"
             :tooltip="'#'+getTimer.task.id+': '+getTimer.task.title" @click="$router.push({name: 'TaskDetails', params: {id: getTimer.task.id}})">{{ getTimer.formatted }}
        </div>
      </template>
      <button v-if="getTimer.paused" @click="$store.dispatch('timerStart', getTimer.task)"
              class="btn btn-outline-secondary btn-sm" tooltip="Timer fortsetzen" position="bottom">
        <i :class="getTimer.paused ? 'fa-duotone fa-play-circle' : 'fa-duotone fa-pause-circle'"></i>
      </button>
      <button v-else @click="$store.dispatch('timerPause')" class="btn btn-outline-secondary btn-sm"
              tooltip="Timer pausieren" position="bottom">
        <i :class="getTimer.paused ? 'fa-duotone fa-play-circle' : 'fa-duotone fa-pause-circle'"></i>
      </button>
      <button @click="openPopupTimer()" class="btn btn-outline-primary btn-sm" tooltip="Timer stoppen"
              position="bottom">
        <i class="fa-duotone fa-stop-circle"></i>
      </button>
    </template>
    <Modal v-if="getTimer && openTime">
      <div class="modal fade show" style="display: block;">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">Aufwand hinzufügen</div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <h6 class="mb-3" v-if="getTimer && getTimer.task">{{getTimer.task.title}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <multiselect v-model="message.activity_id"    :label="'title'" :options="getConfigs.issue_activities"
                               :modelValue="'id'"
                               :valueProp="'id'"
                               :multiple="false"
                               :canClear="false"
                               :searchable="true"
                               :closeOnSelect="true"
                               :placeholder="'Aktivität wählen'"
                               :trackBy="'title'" class="mb-3 small">

                  </multiselect>
                  <textarea v-model="message.comment" class="form-control"/><br>
                 </div>
                </div>
              <div class="row">
                <div class="col-lg-6 col-12">
                  <button :disabled="!message.activity_id || !message.comment" class="btn btn-outline-primary"
                          @click="$store.dispatch('timerEnd', message); message.comment = ''; message.activity_id = null, openTime = false;"><i class="fa-duotone fa-envelope"></i> Senden
                  </button>
                </div>
                <div class="col-lg-6 col-12">
                  <button   class="btn btn-outline-danger"
                          @click="$store.dispatch('timerReset'); message.comment = ''; message.activity_id = null, openTime = false;"><i class="fa-duotone fa-trash"></i> Verwerfen
                  </button>
                </div>
              </div>
              </div>

          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Multiselect from '@vueform/multiselect'

export default {
  name: "Timer",
  data() {
    return {
      openTime: false,
      message: {
        activity_id: null,
        comment: '',
      }
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getConfigs", "getTimer"]),
  },
  methods: {
    openPopupTimer() {
      this.openTime = true;
    },
  }
};
</script>

