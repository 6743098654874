<template>
  <div class="appView activities">


      <div class=" activities">
        <div class="row mb-3">

          <div class="col-6 col-lg-3">
            <div class="form-group">
              <label class="color">Von</label>
              <VudeDatePicker placeholder="Von" v-model="date_from" :vertical="true" model-type="yyyy-MM-dd"  format="dd.MM.yyyy" locale="de"  :auto-apply="true" :close-on-auto-apply="true" :enable-time-picker="false"  cancel-text="Schließen" select-text="Auswählen" :teleport="true" show-now-button now-button-label="Heute"  @update:model-value="filter()"  />
            </div>

              </div>
          <div class="col-6 col-lg-3">
            <div class="form-group">
              <label class="color">Bis</label>
              <VudeDatePicker v-model="date_to" placeholder="Bis" :vertical="true" model-type="yyyy-MM-dd"   format="dd.MM.yyyy" locale="de"  :auto-apply="true" :close-on-auto-apply="true" :enable-time-picker="false"  cancel-text="Schließen" select-text="Auswählen" :teleport="true" show-now-button now-button-label="Heute"  @update:model-value="filter()"  />
            </div>


            </div>
          </div>

          <div class="activityWrap" v-if="activities.length">
            <table class="table-hover table-sm table">
              <thead>
              <tr>
                <th class="center">Mitarbeiter</th>
                <th>Zeit</th>
                <th>Typ</th>
                <th>Ereignis</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="activity in activities" v-bind:key="activity.id">
                <td class="center">
                  <img :src="$filters.employeeIcon(getEmployees, activity.u_id, 'u_id')" class="sketchAvatar">
                </td>
                <td>{{ $filters.formatDateTime(activity.a_time) }}</td>
                <td>
                  <template v-if="activity.a_type === 'document'||activity.a_type === 'voucher'||activity.a_type === 'document_queue'"><span tooltip="Dokument"><i class="fa-duotone fa-file"></i></span></template>
                  <template v-else-if="activity.a_type === 'payment'"><span tooltip="Bezahlung"><i class="fa-duotone fa-euro-sign"></i></span></template>
                  <template v-else-if="activity.a_type === 'payment'"><span tooltip="Bezahlung"><i class="fa-duotone fa-euro-sign"></i></span></template>
                  <template v-else-if="activity.a_type === 'phone_incoming'"><span tooltip="Anruf"><i class="fa-duotone fa-phone-alt"></i></span></template>
                  <template v-else-if="activity.a_type === 'changeDBOpos'|| activity.a_type ==='customersepa'"><span tooltip="Buchhaltung"><i class="fa-receipt fa-duotone"></i></span></template>
                  <template v-else-if="activity.a_type === 'project_updated'||activity.a_type === 'project_created'||activity.a_type === 'address'||activity.a_type === 'customernotice'||activity.a_type === 'customer_reactivated'||activity.a_type === 'customer_ownerChanged'||activity.a_type === 'customer_created'||activity.a_type === 'customer_queue'||activity.a_type === 'customersnoozeDate'||activity.a_type === 'customer_akquiseChanged'||activity.a_type === 'customerdeleted'||activity.a_type === 'customer'||activity.a_type === 'customer_updated'"><span tooltip="Kunde"><i class="fa-users fa-duotone"  ></i></span></template>
                  <template v-else-if="activity.a_type === 'customer_file_upload'|| activity.a_type ==='customercustomFile'|| activity.a_type ==='customercustomFiles'"><span tooltip="Datei"><i class="fa-file fa-duotone"></i></span></template>
                  <template v-else-if="activity.a_type === 'employee_queue'"><span tooltip="Mitarbeiter"><i class="fa-id-card-clip fa-duotone"></i></span></template>
                  <template v-else>
                   <span class="badge small">{{ activity.a_type }}</span>
                  </template>

                </td>
                <td v-html="activity.a_description"></td>
              </tr>
              </tbody>
            </table>


          </div>


      </div>
    </div>
</template>
<style>
.img-employee {
  width: 50px;
}
</style>
<script>
import { ref } from 'vue';
import axios from 'axios';
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]

export default {
  name: 'ActivitiesOverview',
  components: {VueDatePicker},
  computed: {
    ...mapGetters(["getEmployees"]),
  },
  methods: {
    /*
    setAbonnement(abonnement) {

      this.activeProduct = abonnement;
      //this.$router.push({name: "AbonnementDetails", params: {id: product.id}});

    },
    */

    filter() {
      let VueInner = this;
      this.filters = '&date_from=' + VueInner.date_from + '&date_to=' + VueInner.date_to;
      this.getActivities();
    },
    getActivities() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "activity/search?limit=100" + this.filters, {headers}).then((r) => {
        this.activities = r.data.result.data;
        this.loading = false;
      });
    },
  },
  data() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const isoTomorrow = tomorrow.toISOString().substr(0, 10);
    return {
      activities: [],
      date_from: new Date().toISOString().substr(0, 10),
      date_to: isoTomorrow,
      filters: null,
    };
  },
  created() {
    this.a = this.activities;
    this.filter();
    /*
    let Vueinner = this;
    this.loading = true;
    const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
    */
    //this.getActivities();
  },
}
</script>
