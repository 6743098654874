<template>
  <div class="row mb-3 timesBookingView">
    <div class="col-12">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label for="typef">Type</label>
                <select id="typef" class="form-select small" v-model="filter_type" @change="filter">
                  <option value="">Alle</option>
                  <option value="invoice">Nur Abrechnungsfähige</option>
                  <option value="working">Nicht Abrechnungsfähige</option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label for="month">Monat</label>
                <select id="month" class="form-select small" v-model="filter_month" @change="filter">
                  <option value="">Alle</option>
                  <option value="01">Januar</option>
                  <option value="02">Februar</option>
                  <option value="03">März</option>
                  <option value="04">April</option>
                  <option value="05">Mai</option>
                  <option value="06">Juni</option>
                  <option value="07">Juli</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">Oktober</option>
                  <option value="11">November</option>
                  <option value="12">Dezember</option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label for="year">Jahr</label>
                <select id="year" class="form-select small" v-model="filter_year" @change="filter">
                  <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body infoBody">
          <div class="row">
            <div class="col-12" v-for="(customers, cKey) in times" :key="cKey" :class="(activeCustomer === cKey) ? 'active' : ''">
              <div class="customers">
                <div class="row outer">
                  <div class="col-2">
                    <template v-if="customers.info.id">{{ customers.info.id }}</template>
                    <template v-else>Unbekannt!</template>
                  </div>
                  <div class="col-4">
                    <template v-if="customers.info.name">{{ customers.info.name }}</template>
                    <template v-else>Unbekannt!</template>
                  </div>
                  <div class="col-4">
                    <template v-if="customers.sum">{{ customers.sum }}</template>
                    <template v-else>Unbekannt!</template>
                  </div>
                  <div class="col-2">
                    <button class="btn btn-sm" :class="(activeCustomer === cKey) ? 'btn-primary' : 'btn-outline-primary'"
                            @click="(activeCustomer !== cKey) ? activeCustomer = cKey : activeCustomer =''">
                      <template v-if="activeCustomer === cKey">
                        <i class="fa-duotone fa-minus"></i>
                      </template>
                      <template v-else>
                        <i class="fa-duotone fa-plus"></i></template>
                    </button>
                  </div>
                </div>
                <div class="row" v-if="activeCustomer === cKey">
                  <div class="col-12" v-for="(projects, pKey) in customers.projects" :key="cKey">
                    <div class="projects">
                      <div class="row outer">
                        <div class="col-2">
                          <template v-if="customers.info.id">{{ projects.info.id }}</template>
                          <template v-else>Unbekannt!</template>
                        </div>
                        <div class="col-4">
                          <template v-if="customers.info.name">{{ projects.info.name }}</template>
                          <template v-else>Unbekannt!</template>
                        </div>
                        <div class="col-4">
                          <template v-if="customers.sum">{{ projects.sum }}</template>
                          <template v-else>Unbekannt!</template>
                        </div>
                        <div class="col-2">
                          <button class="btn btn-sm" :class="(activeProject === pKey) ? 'btn-secondary' : 'btn-outline-secondary'"
                                  @click="(activeProject !== pKey) ? activeProject = pKey : activeProject =''">
                            <template v-if="activeProject === pKey">
                              <i class="fa-duotone fa-chevron-up"></i>
                            </template>
                            <template v-else>
                              <i class="fa-duotone fa-chevron-down"></i>
                            </template>
                          </button>
                          <button  v-if="activeProject === pKey" class="btn btn-sm btn-outline-primary ms-1" @click="createProjectInvoice(projects.info.id, customers.info.id)"><i class="fa-file-contract fa fa-duotone"></i> Rechnung</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="activeProject === pKey">
                      <div class="col-12">
                        <div class="table-responsive">
                          <table class="table table-sm table-bordered">
                            <thead>
                            <tr>
                              <th>Tag</th>
                              <th>Wer</th>
                              <th>Tracker</th>
                              <th>Aktivität</th>
                              <th>Projekt</th>
                              <th>Ticket</th>
                              <th>Kommentar</th>
                              <th>Rechnung</th>
                              <th>Stunden</th>
                              <th>In Abrechnung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(issue, iKey) in projects.issues">
                              <tr>
                                <td colspan="100%" class="issueTitle">{{ issue.info.name }}</td>
                              </tr>
                              <template v-for="(day, dKey) in issue.dates">

                                <tr v-for="(time, tKey) in day.items" :key="tKey">
                                  <td>{{ $filters.formatDate(time.date) }}</td>

                                  <td>{{ time.employeeName }}</td>
                                  <td @click="openChanger('tracker', time.id, time.trackerId, time.customerId)">{{ time.trackerTitle }}</td>
                                  <td @click="openChanger('activity', time.id, time.activity_id, time.customerId)">{{ time.activityTitle }}</td>
                                  <td @click="openChanger('project', time.id, time.projectId, time.customerId)">{{projects.info.name}}</td>
                                  <td>{{ time.issueTitle }}</td>
                                  <td @click="editCommentWindow(time.id, time.comment)">
                                    {{time.comment}}
                                  </td>
                                  <td>{{ time.hours }}</td>
                                  <td>{{ time.roundHours }}</td>
                                  <td><template v-if="time.activityState === 'calc'">{{time.roundHours}}</template><template v-else>0</template></td>
                                </tr>

                                <tr class="calcRow">
                                  <td colspan="7" >&nbsp;
<!--                                    {{  $filters.formatDate(dKey) }}-->
                                  </td>
                                  <td>{{ day.sum }}</td>
                                </tr>
                              </template>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal v-if="editComment">
        <div class="modal fade show " tabindex="-1"
             aria-hidden="true" style="display: block;">
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Kommentar ändern</h5>
                <button type="button" class="btn-close" @click="editComment = false;"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                   <textarea class="form-control" v-model="editCommentValue"></textarea></div>
                    <button @click="saveComment()" class="btn btn-success">Speichern</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal v-if="changer === true">
        <div class="modal fade show " tabindex="-1"
             aria-hidden="true" style="display: block;">
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ $t('globals.'+changerType) }} ändern</h5>
                <button type="button" class="btn-close" @click="changer = false;"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <button v-for="opt in changerOptions" :class="(changerActive === opt.id || changerActive === opt.id.toString()) ? 'btn-primary' : 'btn-outline-primary' " type="button" class="btn btn-sm mb-2 w-100   " :position="'left'"
                            @click="updateTimeWith(changerType, changerId, opt.id)">{{ opt.title }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Datepicker from '@vuepic/vue-datepicker';
import customersearch from "@/filter/components/customersearch";

export default {
  name: 'TicketTime',
  components: {
    Datepicker, customersearch
  },
  computed: {
    ...mapGetters(['getUser', 'getEmployees', 'getConfigs']),
    timeHoursFull() {
      let hours = 0;
      for (let i = 0; i < this.times.length; i++) {
        if (this.times[i].hours) {
          hours += parseFloat(this.times[i].hours);
        }
      }
      hours = hours.toFixed(2);
      return hours;
    },
    years() {
      let years = [];
      for (let i = 2020; i <= new Date().getFullYear(); i++) {
        years.push(i);
      }
      return years;
    }
  },
  data() {
    return {
      editComment: false,
      editCommentValue: '',

      changer: false,
      changerOptions: [],
      changerActive: false,
      changerType: '',
      changerId: '',
      selectedEmployee: null,
      selectedMonth: null,
      selectedYear: null,
      activeCustomer: null,
      activeProject: null,
      filter_month: '',
      filter_year: '',
      filter_type: '',
      times: []
    }
  },
  methods: {
    editCommentWindow(id, comment) {
      this.editComment = id;
      this.editCommentValue = comment;
    },
    saveComment() {
      this.updateTimeWith("comment", this.editComment, this.editCommentValue);
    },
    updateTimeWith(type, id, value) {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "issueTimes/" + id + "/update", {type: type, value: value}, {headers}).then((r) => {
        VueInner.$store.dispatch("handleApiResponse", r);
        VueInner.changer = false;
        VueInner.editComment = false;
        VueInner.filter();
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    openChanger(type, id, activeId, customerId) {
      this.changer = true;
      this.changerType = type;
      this.changerId = id;
      this.changerActive = activeId;
      switch (type) {
        case 'project':
          axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + customerId + "&filter_state=active&limit=0", {headers: {"crmAuth": localStorage.getItem("sketchManagerToken")}}).then((r) => {
            this.changerOptions = r.data.result.data;
          }).catch((r) => {
            this.$store.dispatch("handleApiResponse", r.response);
          });
          break;
        case 'activity':
          this.changerOptions = this.getConfigs.issue_activities;
          break;
        case 'tracker':
          this.changerOptions = this.getConfigs.issue_trackers;
          break;
        default:
          alert("not found");
          break;
      }

    },
    createProjectInvoice(id, customerId) {

      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "project/"+id+"/createInvoice", {customer_id: customerId, month:this.filter_month, year:this.filter_year}, {headers}).then((r) => {
        VueInner.$store.dispatch("handleApiResponse", r);
        VueInner.filter();
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });


    },
    filter() {
      this.filter_query = '';
      if (this.filter_type) {
        this.filter_query += '?filter_type=' + this.filter_type;
      } else {
        this.filter_query += '?filter_type=';
      }
      if (this.filter_month) {
        this.filter_query += '&filter_month=' + this.filter_month;
      }
      if (this.filter_year) {
        this.filter_query += '&filter_year=' + this.filter_year;
      }
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "issueTimes/invoices" + this.filter_query, {headers}).then((r) => {
        VueInner.times = r.data.result.data;
      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });
    },
    setDefaults() {
      if (this.getUser && this.getUser._isEmployee) {
        this.filter_owner_id = this.getUser._isEmployee;
      }
      // get Current month like this: 01, 02, 03, ...
      let date = new Date();
      this.filter_month = ("0" + (date.getMonth() + 1)).slice(-2);
      this.filter_year = date.getFullYear();
    },
  },
  mounted() {
    this.setDefaults();
    this.filter();
  },
}
</script>
